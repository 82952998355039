<template>
  <div class="configure-dialog-step-six">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">{{ $t('vue.contactForm') }}</div>
          <vx-tooltip :text="$t('info.contactForm')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </vs-col>
        <!-- <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="translation--container">
            <div class="mr-4">
              <info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" />
            </div>
            <div v-html="$t('info.translation')"></div>
          </div>
        </vs-col> -->
      </div>
    </div>
    <div class="flex-view">
      <div class="mt-5 mr-6 flex flex-column flex-grow" style="max-width: 640px">
        <div v-if="canUpdateTextFields">{{ $t('vue.messageIfNobodyAvailable') }}</div>
        <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('vue.messageIfNobodyAvailable') }} (English)</div>

        <vs-textarea placeholder="" counter="300" height="100px" v-model="contactFormMessage" class="w-full p-1" />
        <div v-if="!canUpdateTextFields" class="w-full bold">
          {{ $t('vue.messageIfNobodyAvailable') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})
        </div>
        <vs-textarea v-if="!canUpdateTextFields" placeholder="" counter="300" height="100px" v-model="tWebConnectContactFormText" class="w-full p-1" />

        <div class="feedback-form-colors flex flex-row flex-wrap justify-between w-full">
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.contactFormBackgroundColor') }}</div>
            <div><input class="pa-color-picker" type="color" v-model="backgroundColor" name="contactFormBackgroundColor" /></div>
          </div>
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.fontColor') }}</div>
            <div><input class="pa-color-picker" type="color" v-model="fontColor" name="contactFormFontColor" /></div>
          </div>
        </div>

        <div class="mt-4 flex flex-row items-center justify-center" :class="!HAS_SCHEDULING_ACCESS ? 'feature-disabled' : ''">
          <div class="mt-4 flex justify-center items-center">
            <div class="mr-2" v-if="HAS_SCHEDULING_ACCESS" @click="contactFormIsBookingEnabled = !contactFormIsBookingEnabled">
              <toggle-switch-icon :enabled="contactFormIsBookingEnabled" :width="40" :height="40" />
            </div>
            <div class="mr-2">{{ $t('vue.meetingSlotBooking') }}</div>
            <div>
              <vx-tooltip v-if="HAS_SCHEDULING_ACCESS" :text="$t('info.meetingSlotBooking')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
              <vx-tooltip v-else :text="$t('upgrade-messages.locked-feature')" position="top" style="height: 20px">
                <small-lock-icon />
              </vx-tooltip>
            </div>
          </div>
        </div>

        <div class="mt-4 flex items-center" v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS">
          <div class="mr-2">{{ $t('vue.bookableAppointments') }}</div>
          <vx-tooltip :text="$t('info.bookableAppointments')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </div>

        <v-select
          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
          class="mb-4 md:mb-0 w-full"
          :options="appointments"
          :clearable="false"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="selectedAppointment"
          :disabled="!HAS_SCHEDULING_ACCESS"
        >
          <template v-slot:option="option">
            <div>
              {{ option.label }}
            </div>
          </template>

          <template #no-options>{{ '' }}</template>

          <template #list-footer>
            <div
              class="configure-dialog-step-six__select-link-message"
              @click="$router.push({ name: 'company-setup', params: { action: 'create-appointment' } })"
            >
              <CalendarIcon color="#3B86F7" class="configure-dialog-step-six__select-link-message__icon" :width="13" :height="13" />

              {{ $t('vue.createNewBookableAppointments') }}
            </div>
          </template>
        </v-select>

        <div class="mt-4">{{ $t('vue.contactFormFields') }}</div>
        <div class="contact-form-fields flex flex-row flex-wrap justify-between w-full">
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.name') }}</div>
              <div v-if="!contactFormIsBookingEnabled" style="margin-left: auto">
                <div @click="toggleFields('name')">
                  <toggle-switch-icon :enabled="contactFormFields.name.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox :disabled="contactFormIsBookingEnabled" v-model="contactFormFields.name.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.email') }}</div>
            </div>
            <div>
              <vs-checkbox disabled v-model="contactFormFields.email.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column" v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.date') }}</div>
              <div v-if="!contactFormIsBookingEnabled" style="margin-left: auto">
                <div @click="contactFormFields.date.enabled = !contactFormFields.date.enabled">
                  <toggle-switch-icon :enabled="contactFormFields.date.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox :disabled="contactFormIsBookingEnabled" v-model="contactFormFields.date.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column" v-if="!contactFormIsBookingEnabled">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.phone') }}</div>
              <div style="margin-left: auto">
                <div @click="contactFormFields.phone.enabled = !contactFormFields.phone.enabled">
                  <toggle-switch-icon :enabled="contactFormFields.phone.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox v-model="contactFormFields.phone.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('inputs.country') }}</div>
              <div style="margin-left: auto">
                <div @click="contactFormFields.country.enabled = !contactFormFields.country.enabled">
                  <toggle-switch-icon :enabled="contactFormFields.country.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox v-model="contactFormFields.country.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('inputs.company') }}</div>
              <div style="margin-left: auto">
                <div @click="contactFormFields.company.enabled = !contactFormFields.company.enabled">
                  <toggle-switch-icon :enabled="contactFormFields.company.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox v-model="contactFormFields.company.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('inputs.message') }}</div>
              <div style="margin-left: auto">
                <div @click="contactFormFields.message.enabled = !contactFormFields.message.enabled">
                  <toggle-switch-icon :enabled="contactFormFields.message.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox v-model="contactFormFields.message.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
        </div>
        <!-- PRIVACY INFORMATION -->
        <div class="mt-4 flex flex-row items-center justify-center">
          <div class="headline">{{ $t('vue.privacyInformation') }}</div>
          <div class="ml-2">
            <vx-tooltip :text="$t('info.privacyInformation')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="contactFormIsPrivacyInformationEnabled = !contactFormIsPrivacyInformationEnabled">
              <toggle-switch-icon :enabled="contactFormIsPrivacyInformationEnabled" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.enablePrivacyInformation') }}</div>
        </div>
        <div class="mt-4 mr-2 w-full" v-if="contactFormIsPrivacyInformationEnabled">
          <div v-if="canUpdateTextFields" class="w-full">{{ $t('vue.privacyInformationText') }}</div>
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('vue.privacyInformationText') }} (English)</div>
          <quill-editor v-model="contactFormPrivacyInformationHtml" ref="quillEditorA" :options="editorOption"> </quill-editor>
          <div v-if="!canUpdateTextFields" class="w-full bold">
            {{ $t('vue.privacyInformationText') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})
          </div>
          <quill-editor v-if="!canUpdateTextFields" v-model="tWebConnectPrivacyInformationHtml" ref="quillEditorA" :options="editorOption"> </quill-editor>
        </div>

        <!-- SEND A MESSAGE -->
        <div class="mt-4 flex flex-row items-center justify-center">
          <div class="headline">{{ $t('vue.sendMessageEndMessage') }}</div>
          <div class="ml-2">
            <vx-tooltip :text="$t('info.sendMessageEndMessage')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="showSendMessagePreview = !showSendMessagePreview">
              <toggle-switch-icon :enabled="showSendMessagePreview" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.showPreview') }}</div>
        </div>

        <div v-if="canUpdateTextFields" class="mt-4 w-full">{{ $t('inputs.title') }}</div>
        <div v-if="!canUpdateTextFields" class="mt-4 w-full bold">{{ $t('inputs.title') }} (English)</div>
        <vs-input class="w-full mr-2" v-model="contactFormSendMessageTitle" />
        <div v-if="!canUpdateTextFields" class="w-ful bold">{{ $t('inputs.title') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})</div>
        <vs-input v-if="!canUpdateTextFields" class="w-full bold mr-2" v-model="tWebConnectSendMessageDefaultTitle" />

        <div v-if="canUpdateTextFields" class="mt-4 w-full">{{ $t('inputs.message') }}</div>
        <div v-if="!canUpdateTextFields" class="mt-4 w-full bold">{{ $t('inputs.message') }} (English)</div>
        <vs-textarea counter="300" height="100px" v-model="contactFormSendMessageCopy" class="w-full p-1" />
        <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('inputs.message') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})</div>
        <vs-textarea v-if="!canUpdateTextFields" counter="300" height="100px" v-model="tWebConnectSendMessageDefaultCopy" class="w-full p-1" />

        <div class="mt-4 flex flex-row items-center justify-center">
          <div class="headline">{{ $t('vue.contactFormInsideChatTitle') }}</div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2">Custom</div>
          <div class="mr-2">
            <div @click="contactFormIsDefaultContactFormInsideChat = !contactFormIsDefaultContactFormInsideChat">
              <toggle-switch-icon :enabled="contactFormIsDefaultContactFormInsideChat" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">Default</div>
        </div>

        <!-- MEETING SLOT BOOKING -->

        <div
          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
          class="mt-4 flex flex-row items-center justify-center"
          :class="!HAS_SCHEDULING_ACCESS ? 'feature-disabled' : ''"
        >
          <div class="headline">{{ $t('vue.bookingSlotEndMessage') }}</div>
          <div class="ml-2">
            <vx-tooltip v-if="HAS_SCHEDULING_ACCESS" :text="$t('info.bookingSlotEndMessage')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
            <vx-tooltip v-else :text="$t('upgrade-messages.locked-feature')" position="top">
              <vs-icon icon="lock" style="display: inline-block"></vs-icon>
            </vx-tooltip>
          </div>
        </div>

        <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS" class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="displayAppointmentBookedPreview">
              <toggle-switch-icon :enabled="showBookingSlotPreview" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.showPreview') }}</div>
        </div>
        <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && canUpdateTextFields" class="mt-4 w-full">
          {{ $t('inputs.title') }}
        </div>
        <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && !canUpdateTextFields" class="mt-4 w-full bold">
          {{ $t('inputs.title') }} (English)
        </div>
        <vs-input
          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
          v-model="contactFormBookingSlotTitle"
          class="w-full mr-2"
          :disabled="!HAS_SCHEDULING_ACCESS"
        />
        <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && !canUpdateTextFields" class="w-full bold">
          {{ $t('inputs.title') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})
        </div>
        <vs-input
          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && !canUpdateTextFields"
          v-model="tWebConnectBookingSlotDefaultTitle"
          class="w-full mr-2"
          :disabled="!HAS_SCHEDULING_ACCESS"
        />
        <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && canUpdateTextFields" class="mt-4 w-full">
          {{ $t('inputs.message') }}
        </div>
        <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && !canUpdateTextFields" class="mt-4 bold w-full">
          {{ $t('inputs.message') }} (English)
        </div>
        <vs-textarea
          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
          counter="300"
          height="100px"
          v-model="contactFormBookingSlotCopy"
          class="w-full p-1"
          :class="!HAS_SCHEDULING_ACCESS ? 'feature-disabled' : ''"
          :disabled="!HAS_SCHEDULING_ACCESS"
        />
        <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && !canUpdateTextFields" class="w-full bold">
          {{ $t('inputs.message') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})
        </div>
        <vs-textarea
          name="bookingslotMessage"
          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS && !canUpdateTextFields"
          counter="300"
          height="100px"
          v-model="tWebConnectBookingSlotDefaultCopy"
          class="w-full p-1"
          :class="!HAS_SCHEDULING_ACCESS ? 'feature-disabled' : ''"
          :disabled="!HAS_SCHEDULING_ACCESS"
        />
        <span class="text-danger text-sm" v-show="errors.has('bookingslotMessage')">{{ errors.first('bookingslotMessage') }}</span>
      </div>

      <div class="flex flex-column" style="margin-left: auto">
        <div class="flex">
          {{ $t('vue.preview') }}
        </div>
        <div class="relative flex flex-column overflow-hidden rounded-lg default-contact-form-preview" v-if="showContactFormPreview">
          <div class="flex justify-center items-center" :style="contactFormBackgroundStyle">
            <div class="message-container" :style="contactFormStyle">
              <scroll-view ref="chatScrollArea" class="contact-form-scroll-area">
                <div style="position: absolute; right: 0px; top: 0px">
                  <div class="close-box flex justify-center items-center" :style="closeButton">
                    <close-icon :width="16" :height="16" :color="fontColor"></close-icon>
                  </div>
                </div>
                <div class="message-container-title">
                  {{ $t('vue.contactForm') }}
                </div>
                <div class="mt-4 message-container-copy">
                  <span style="white-space: pre-line">{{ dialogContactFormMessage }}</span>
                </div>

                <div class="flex mt-4 justify-center items-center">
                  <div class="message-container-upper">{{ $t('vue.sendAMessage') }}</div>

                  <div
                    class="ml-2 mr-2 message-container-upper pointer"
                    style="margin-top: 5px"
                    @click="isBookingEnabled = !isBookingEnabled"
                    v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
                  >
                    <switch-on-icon color="#12598D" :width="41" :height="20" v-if="isBookingEnabled"></switch-on-icon>
                    <switch-off-icon color="#B2B2B2" :width="41" :height="20" v-if="!isBookingEnabled"></switch-off-icon>
                  </div>
                  <div v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS" class="message-container-upper">{{ $t('vue.bookAMeeting') }}</div>
                </div>

                <!-- CONTACT FORM FIELDS -->
                <div class="mt-4 message-container-fields" v-show="dialog.contactForm.fields.name.enabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <user-icon :width="15" :height="15" color="#262629"></user-icon>
                  </div>
                  <div class="upper-case">{{ $t('vue.name') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.name.required">*</span>
                </div>
                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.email.enabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <mail-icon :width="15" :height="15" color="#262629"></mail-icon>
                  </div>
                  <div class="upper-case">{{ $t('vue.emailAddress') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.email.required">*</span>
                </div>
                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.date.enabled && contactFormIsBookingEnabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <calendar-icon :width="15" :height="15" color="#262629"></calendar-icon>
                  </div>
                  <div class="upper-case">{{ $t('vue.date') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.date.required">*</span>
                </div>
                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.phone.enabled && !contactFormIsBookingEnabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <VerticalPhoneIcon :width="15" :height="15" color="#262629" />
                  </div>
                  <div class="upper-case">{{ $t('vue.phone') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.phone.required">*</span>
                </div>
                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.country.enabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <FlagIcon :width="15" :height="15" color="#262629" />
                  </div>
                  <div class="upper-case">{{ $t('vue.country') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.country.required">*</span>
                </div>

                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.company.enabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <CompanyIcon :width="15" :height="15" color="#262629" />
                  </div>
                  <div class="upper-case">{{ $t('vue.company') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.company.required">*</span>
                </div>

                <div class="mt-2 message-container-fields ht-100" v-if="dialog.contactForm.fields.message.enabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <message-square-icon :width="15" :height="15" color="#262629"></message-square-icon>
                  </div>
                  <div class="upper-case">{{ $t('inputs.message') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.message.required">*</span>
                </div>

                <!-- CONTACT FORM FIELDS -->

                <div style="margin-top: 40px" v-if="contactFormIsPrivacyInformationEnabled" class="flex flex-row">
                  <div>
                    <vs-checkbox
                      class="configure-dialog-step-six-checkbox"
                      :style="{
                        '--contact-form-darken-background': dialog.contactForm.sendButtonBackgroundColor,
                        '--contact-form-font-color': fontColor
                      }"
                      v-model="hasAgreedToPrivacy"
                    >
                    </vs-checkbox>
                  </div>
                  <div
                    class="message-container-privacy-policy"
                    :style="{
                      '--contact-form-font-color': fontColor
                    }"
                    v-html="dialogContactFormPrivacyInformationHtml"
                  ></div>
                </div>
                <div :style="contactFormSendButtonBackgroundStyle" class="message-container-action-button flex items-center justify-center">
                  {{ isBookingEnabled ? $t('vue.bookMeeting') : $t('vue.sendMessage') }}
                </div>
              </scroll-view>
            </div>
          </div>

          <powered-by />
        </div>

        <div class="flex flex-column relative" style="margin-left: auto" v-if="showSendMessagePreview">
          <div style="position: absolute; right: 20px; top: 20px; cursor: pointer">
            <close-icon :width="16" :height="16"></close-icon>
          </div>
          <div class="flex justify-center items-center" :style="contactFormFeedbackStyle">
            <div class="feedback-message-container">
              <div class="feedback-message-container-title">
                {{ dialogContactFormSendMessageTitle }}
              </div>
              <div class="mt-4 feedback-message-container-copy">
                {{ dialogContactFormSendMessageCopy }}
              </div>
            </div>
          </div>
          <powered-by />
        </div>

        <div class="flex flex-column relative" style="margin-left: auto" v-if="showBookingSlotPreview">
          <div style="position: absolute; right: 20px; top: 20px; cursor: pointer">
            <close-icon :width="16" :height="16"></close-icon>
          </div>
          <div class="flex justify-center items-center" :style="contactFormFeedbackStyle">
            <div class="feedback-message-container">
              <div class="feedback-message-container-title">
                {{ dialogBookingSlotDefaultTitle }}
              </div>
              <div class="mt-4feedback-message-container-copy">
                {{ dialogBookingSlotDefaultCopy }}
              </div>
            </div>
          </div>

          <powered-by />
        </div>

        <div class="flex mt-4">
          {{ 'Contact form inside chat preview' }}
        </div>
        <div class="relative flex flex-column">
          <div class="inside-chat-cf">
            <div
              class="inside-chat-cf__heading"
              :style="{
                background: toolBarBackground
              }"
            >
              <div class="inside-chat-cf__heading__minimize">
                <back-icon :color="'#fff'"></back-icon>
              </div>
              <div class="inside-chat-cf__heading__title"><back-icon :color="'#fff'"></back-icon><span>Let's Connect</span></div>
              <div class="inside-chat-cf__heading__buttons">
                <div class="inside-chat-cf__heading__button">
                  <phone-call-icon :width="15" color="#262629" style="margin-right: 5px"></phone-call-icon>Phone call
                </div>
                <div class="inside-chat-cf__heading__button">
                  <video-call-icon :width="15" color="#262629" style="margin-right: 5px"></video-call-icon>Video call
                </div>
                <div class="inside-chat-cf__heading__endcall">
                  <phone-icon color="#fff"></phone-icon>
                </div>
              </div>
            </div>
            <div
              class="inside-chat-cf__content"
              :style="{
                '--contact-form-inside-background': dialog.contactForm.isDefaultContactFormInsideChat ? '#EFF1F5' : toolBarBackground
              }"
            >
              <scroll-view ref="insideChatArea" style="height: 350px">
                <div class="inside-chat-cf__content__first-message">
                  <div v-html="$t('vue.waitingRoomDefaultMessage')"></div>
                </div>
                <div class="inside-chat-cf__content__second-message">
                  <span class="flex" v-html="$t('vue.waitingRoomVisitorMessage')"></span>
                </div>
                <div class="flex justify-center items-center">
                  <div class="message-container" :style="contactFormInsideChatStyle">
                    <scroll-view ref="chatScrollAreaView" class="inside-chat-cf__content--inner-scroll-view">
                      <div class="mt-4 message-container-copy" :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`">
                        {{ dialogContactFormMessage }}
                      </div>
                      <div class="flex mt-4 justify-center items-center">
                        <div
                          class="message-container-upper"
                          :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
                          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
                        >
                          {{ $t('vue.sendAMessage') }}
                        </div>

                        <div
                          class="ml-2 mr-2 message-container-upper pointer"
                          style="margin-top: 5px"
                          @click="isBookingEnabled = !isBookingEnabled"
                          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
                        >
                          <switch-on-icon
                            :color="dialog.contactForm.isDefaultContactFormInsideChat ? '#494A4E' : contactFormInsideChatSendButtonBackgroundColor"
                            :width="41"
                            :height="20"
                            v-if="isBookingEnabled"
                          ></switch-on-icon>
                          <switch-off-icon color="#B2B2B2" :width="41" :height="20" v-if="!isBookingEnabled"></switch-off-icon>
                        </div>
                        <div
                          :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
                          v-if="contactFormIsBookingEnabled && HAS_SCHEDULING_ACCESS"
                          class="message-container-upper"
                        >
                          {{ $t('vue.bookAMeeting') }}
                        </div>
                      </div>

                      <!-- CONTACT FORM FIELDS -->
                      <div
                        class="mt-4 message-container-fields"
                        :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
                        v-show="dialog.contactForm.fields.name.enabled"
                      >
                        <div class="mr-2" style="margin-top: 1px">
                          <user-icon :width="15" :height="15" color="#262629"></user-icon>
                        </div>
                        <div class="upper-case">{{ $t('vue.name') }}</div>
                        <span class="ml-1" v-if="dialog.contactForm.fields.name.required">*</span>
                      </div>
                      <div
                        class="mt-2 message-container-fields"
                        :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
                        v-if="dialog.contactForm.fields.email.enabled"
                      >
                        <div class="mr-2" style="margin-top: 1px">
                          <mail-icon :width="15" :height="15" color="#262629"></mail-icon>
                        </div>
                        <div class="upper-case">{{ $t('vue.emailAddress') }}</div>
                        <span class="ml-1" v-if="dialog.contactForm.fields.email.required">*</span>
                      </div>
                      <div
                        class="mt-2 message-container-fields"
                        :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
                        v-if="dialog.contactForm.fields.date.enabled && contactFormIsBookingEnabled"
                      >
                        <div class="mr-2" style="margin-top: 1px">
                          <calendar-icon :width="15" :height="15" color="#262629"></calendar-icon>
                        </div>
                        <div class="upper-case">{{ $t('vue.date') }}</div>
                        <span class="ml-1" v-if="dialog.contactForm.fields.date.required">*</span>
                      </div>
                      <div
                        class="mt-2 message-container-fields"
                        :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
                        v-if="dialog.contactForm.fields.phone.enabled && !contactFormIsBookingEnabled"
                      >
                        <div class="mr-2" style="margin-top: 1px">
                          <VerticalPhoneIcon :width="15" :height="15" color="#262629" />
                        </div>
                        <div class="upper-case">{{ $t('vue.phone') }}</div>
                        <span class="ml-1" v-if="dialog.contactForm.fields.phone.required">*</span>
                      </div>

                      <div
                        class="mt-2 message-container-fields ht-100"
                        :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
                        v-if="dialog.contactForm.fields.country.enabled"
                      >
                        <div class="mr-2" style="margin-top: 1px">
                          <flag-icon :width="15" :height="15" color="#262629"></flag-icon>
                        </div>
                        <div class="upper-case">{{ $t('inputs.country') }}</div>
                        <span class="ml-1" v-if="dialog.contactForm.fields.country.required">*</span>
                      </div>
                      <div
                        class="mt-2 message-container-fields ht-100"
                        :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
                        v-if="dialog.contactForm.fields.company.enabled"
                      >
                        <div class="mr-2" style="margin-top: 1px">
                          <company-icon :width="15" :height="15" color="#262629"></company-icon>
                        </div>
                        <div class="upper-case">{{ $t('inputs.company') }}</div>
                        <span class="ml-1" v-if="dialog.contactForm.fields.company.required">*</span>
                      </div>

                      <div
                        class="mt-2 message-container-fields ht-100"
                        :style="`background: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#CFD2D7' : 'rgba(230, 236, 241, 0.6)'}`"
                        v-if="dialog.contactForm.fields.message.enabled"
                      >
                        <div class="mr-2" style="margin-top: 1px">
                          <message-square-icon :width="15" :height="15" color="#262629"></message-square-icon>
                        </div>
                        <div class="upper-case">{{ $t('inputs.message') }}</div>
                        <span class="ml-1" v-if="dialog.contactForm.fields.message.required">*</span>
                      </div>
                      <!-- CONTACT FORM FIELDS -->
                      <div style="margin-top: 40px" v-if="contactFormIsPrivacyInformationEnabled" class="flex flex-row">
                        <div>
                          <vs-checkbox
                            class="configure-dialog-step-six-checkbox"
                            :style="{
                              '--contact-form-darken-background': contactFormInsideChatSendButtonBackgroundColor,
                              '--contact-form-font-color': contactFormInsideChatSendButtonBackgroundColor
                            }"
                            v-model="hasAgreedToPrivacy"
                          >
                          </vs-checkbox>
                        </div>
                        <div
                          class="message-container-privacy-policy"
                          :style="`color: ${dialog.contactForm.isDefaultContactFormInsideChat ? '#262629' : '#fff'}`"
                          v-html="dialogContactFormPrivacyInformationHtml"
                        ></div>
                      </div>
                      <div
                        :style="contactFormSendButtonInsideBackgroundStyle"
                        class="message-container-action-button flex items-center justify-center"
                        style="color: #fff"
                      >
                        {{ isBookingEnabled ? $t('vue.bookMeeting') : $t('vue.sendMessage') }}
                      </div>
                    </scroll-view>
                  </div>
                </div>
              </scroll-view>
            </div>
            <div class="inside-chat-cf__footer">
              <div
                class="inside-chat-cf__footer__toolbar"
                :style="{
                  background: toolBarBackground
                }"
              >
                <clip-icon class="toolbar-container-icon" :width="20" :height="20"></clip-icon>
              </div>
              <div
                class="inside-chat-cf__footer__message"
                :class="{
                  'inside-chat-cf__footer__message--bottom-space': !hasWhiteLabel
                }"
              >
                <div class="inside-chat-cf__footer__message__text">{{ $t('vue.writeMessage') }} ...</div>

                <send-icon :width="25" :height="25" :color="toolBarBackground" />
              </div>
            </div>
            <powered-by class="absolute bottom-0" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import { mapGetters, mapActions } from 'vuex'

const Link = Quill.import('formats/link')
class Mylink extends Link {
  static create(value) {
    const node = super.create(value)
    value = this.sanitize(value)
    if (!value.startsWith('http')) {
      value = `https://${value}`
    }
    node.setAttribute('href', value)
    return node
  }
}
Quill.register(Mylink)

import vSelect from 'vue-select'

import SmallLockIcon from '@/components/icons/SmallLockIcon.vue'
import SendIcon from '@/components/icons/SendIcon.vue'
import ClipIcon from '@/components/icons/ClipIcon.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'
import PhoneCallIcon from '@/components/icons/PhoneCallIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import BackIcon from '@/components/icons/BackIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import SwitchOnIcon from '@/components/icons/SwitchOnIcon.vue'
import SwitchOffIcon from '@/components/icons/SwitchOffIcon.vue'
import MessageSquareIcon from '@/components/icons/MessageSquareIcon.vue'
import FlagIcon from '@/components/icons/FlagIcon.vue'
import CompanyIcon from '@/components/icons/CompanyIcon.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import VerticalPhoneIcon from '@/views/campaigns/visitor/icons/VerticalPhoneIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import MailIcon from '@/components/icons/MailIcon.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import PoweredBy from '@/components/dialog/PoweredBy.vue'
//import InfoIcon from '@/components/icons/InfoIcon.vue'

export default {
  name: 'ContactForm',
  components: {
    quillEditor,
    ScrollView,
    vSelect,
    SmallLockIcon,
    BackIcon,
    SendIcon,
    ClipIcon,
    PhoneIcon,
    PhoneCallIcon,
    VideoCallIcon,
    CloseIcon,
    SwitchOnIcon,
    SwitchOffIcon,
    MessageSquareIcon,
    CalendarIcon,
    VerticalPhoneIcon,
    UserIcon,
    MailIcon,
    ToggleSwitchIcon,
    PoweredBy,
    //InfoIcon,
    CompanyIcon,
    FlagIcon
  },
  props: {
    infoImg: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      appointments: [],
      selectedAppointment: { label: '', value: '' },
      isBookingEnabled: false,
      showContactFormPreview: true,
      showSendMessagePreview: false,
      showBookingSlotPreview: false,
      hasAgreedToPrivacy: false,
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'link']]
        }
      },
      contactFormMessage:
        'We are sorry, none of our agents are available right now. You are welcome to leave your email and we will contact you as soon as possible or you can easily book a meeting at a time that works for you.',
      backgroundColor: '#3B86F7',
      fontColor: '#FFFFFF',
      contactFormIsBookingEnabled: false,
      contactFormIsPrivacyInformationEnabled: false,
      contactFormPrivacyInformationHtml: '',
      contactFormSendMessageTitle: '',
      contactFormSendMessageCopy: '',
      contactFormIsDefaultContactFormInsideChat: false,
      contactFormFields: {
        date: {
          enabled: false,
          required: false
        },
        phone: {
          enabled: true,
          required: true
        },
        name: {
          enabled: true,
          required: true
        },
        message: {
          enabled: true,
          required: true
        },
        email: {
          enabled: true,
          required: true
        },
        country: {
          enabled: false,
          required: false
        },
        company: {
          enabled: false,
          required: false
        }
      },
      tWebConnectContactFormText: '',
      tWebConnectPrivacyInformationHtml: '',
      tWebConnectSendMessageDefaultTitle: '',
      tWebConnectSendMessageDefaultCopy: '',
      tWebConnectBookingSlotDefaultTitle: '',
      tWebConnectBookingSlotDefaultCopy: ''
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      hasWhiteLabel: 'hasWhiteLabel',
      dialog: 'dialog',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation',
      hasLoadedWidgetTranslation: 'widgetTranslation/hasLoadedWidgetTranslation'
    }),
    dialogContactFormMessage() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.contactForm) return this.dialog.contactForm.message
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.contactFormText
      }

      return ''
    },
    dialogContactFormPrivacyInformationHtml() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.contactForm) return this.dialog.contactForm.privacyInformationHtml
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.privacyInformationHtml
      }

      return ''
    },
    dialogContactFormSendMessageTitle() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.contactForm) return this.dialog.contactForm.sendMessageTitle
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.sendMessageDefaultTitle
      }

      return ''
    },
    dialogBookingSlotDefaultTitle() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.contactForm) return this.dialog.contactForm.bookingSlotTitle
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.bookingSlotDefaultTitle
      }

      return ''
    },
    dialogBookingSlotDefaultCopy() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.contactForm) return this.dialog.contactForm.bookingSlotCopy
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.bookingSlotDefaultCopy
      }

      return ''
    },
    dialogContactFormSendMessageCopy() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.contactForm) return this.dialog.contactForm.sendMessageCopy
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.sendMessageDefaultCopy
      }

      return ''
    },
    canUpdateTextFields() {
      if (!this.selectedLanguageForWebConnect || this.selectedLanguageForWebConnect.code === 'en') return true

      return false
    },

    toolBarBackground() {
      return this.dialog.step2ButtonColor || '#3B86F7'
    },
    HAS_SCHEDULING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('scheduling') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    contactFormBackgroundStyle() {
      const backgroundStyle = {
        width: '280px',
        height: '586px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '900px 586px',
        backgroundPosition: '-200px 0px',
        borderRadius: this.hasWhiteLabel ? '13px' : '13px 13px 0 0'
      }

      return backgroundStyle
    },
    contactFormSendButtonBackgroundStyle() {
      return {
        marginTop: '10px',
        backgroundColor: this.contactFormSendButtonBackgroundColor
      }
    },
    closeButton() {
      return {
        backgroundColor: this.contactFormSendButtonBackgroundColor
      }
    },
    contactFormSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.backgroundColor, -16)
    },
    contactFormSendButtonInsideBackgroundStyle() {
      return {
        marginTop: '10px',
        backgroundColor: this.dialog.contactForm.isDefaultContactFormInsideChat ? '#494A4E' : this.contactFormInsideChatSendButtonBackgroundColor
      }
    },
    contactFormInsideChatSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.toolBarBackground, -40)
    },
    contactFormInsideChatStyle() {
      return {
        width: '100%',
        backgroundColor: this.dialog.contactForm.isDefaultContactFormInsideChat ? '#EFF1F5' : this.toolBarBackground,
        color: this.fontColor,
        borderRadius: '13px 13px 13px 0',
        '--contact-form-darken-background': this.contactFormInsideChatSendButtonBackgroundColor
      }
    },
    contactFormStyle() {
      return {
        width: '100%',
        backgroundColor: this.backgroundColor,
        color: this.fontColor,
        borderRadius: this.hasWhiteLabel ? '13px' : '13px 13px 0 0',
        '--contact-form-darken-background': this.contactFormSendButtonBackgroundColor
      }
    },
    contactFormHeight() {
      let heightToReduce = 0
      if (!this.dialog.contactForm.fields.name.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.email.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.phone.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.country.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.company.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.message.enabled) heightToReduce += 100
      const totalHeight = 642 - heightToReduce
      return `${totalHeight}px`
    },
    contactFormFeedbackStyle() {
      return {
        width: '280px',
        height: '586px',
        backgroundColor: this.backgroundColor,
        color: this.fontColor,
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        backgroundPosition: '-280px 0px'
      }
    }
  },
  async mounted() {
    this.$serverBus.$on('validate-step-6', () => {
      this.$validator.validateAll()
    })
    const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
    if (!this.HAS_SCHEDULING_ACCESS) {
      contactForm.isBookingEnabled = false
    }
    contactForm.sendButtonBackgroundColor = this.contactFormSendButtonBackgroundColor

    if (this.activeUserInfo && this.activeUserInfo.company) {
      const bookableAppointmentsRef = await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').get()
      bookableAppointmentsRef.forEach((ref) => {
        const appointment = ref.data()
        this.appointments.push({ label: appointment.name, value: ref.id })
      })

      if (contactForm.selectedAppointment) {
        this.selectedAppointment = this.appointments.find((x) => x.value === contactForm.selectedAppointment)
      }

      if (contactForm) {
        this.contactFormMessage = contactForm.message
        this.backgroundColor = contactForm.backgroundColor
        this.fontColor = contactForm.fontColor

        this.contactFormIsBookingEnabled = Boolean(contactForm.isBookingEnabled)
        this.contactFormIsPrivacyInformationEnabled = Boolean(contactForm.isPrivacyInformationEnabled)
        this.contactFormPrivacyInformationHtml = contactForm.privacyInformationHtml || ''
        this.contactFormBookingSlotTitle = contactForm.bookingSlotTitle || ''
        this.contactFormBookingSlotCopy = contactForm.bookingSlotCopy || ''
        this.contactFormSendMessageTitle = contactForm.sendMessageTitle || ''
        this.contactFormSendMessageCopy = contactForm.sendMessageCopy || ''
        this.contactFormIsDefaultContactFormInsideChat = Boolean(contactForm.isDefaultContactFormInsideChat)

        if (contactForm.fields) {
          this.contactFormFields = JSON.parse(JSON.stringify(contactForm.fields))
          if (typeof this.contactFormFields.country === 'undefined') {
            this.contactFormFields.country.enabled = false
            this.contactFormFields.country.require = false
          }
          if (typeof this.contactFormFields.company === 'undefined') {
            this.contactFormFields.company.enabled = false
            this.contactFormFields.company.require = false
          }
        }
      }

      this.updateDialogPartial({ contactForm })
    }
  },

  watch: {
    contactFormMessage() {
      const contactForm = { ...this.dialog.contactForm } || {}
      contactForm.message = this.contactFormMessage
      this.updateDialogPartial({ contactForm })
    },
    backgroundColor() {
      const contactForm = { ...this.dialog.contactForm } || {}
      contactForm.sendButtonBackgroundColor = this.contactFormSendButtonBackgroundColor
      contactForm.backgroundColor = this.backgroundColor
      this.updateDialogPartial({ contactForm })
    },
    fontColor() {
      const contactForm = { ...this.dialog.contactForm } || {}
      contactForm.fontColor = this.fontColor
      this.updateDialogPartial({ contactForm })
    },
    selectedAppointment() {
      if (this.selectedAppointment) {
        const contactForm = { ...this.dialog.contactForm } || {}
        contactForm.selectedAppointment = this.selectedAppointment.value
        this.updateDialogPartial({ contactForm })
      }
    },
    showContactFormPreview() {
      if (this.showContactFormPreview) {
        this.showSendMessagePreview = false
        this.showBookingSlotPreview = false
      }
    },
    showSendMessagePreview() {
      if (this.showSendMessagePreview) {
        this.showContactFormPreview = false
        this.showBookingSlotPreview = false
      }
      if (!this.showSendMessagePreview && !this.showBookingSlotPreview) {
        this.showContactFormPreview = true
      }
    },
    showBookingSlotPreview() {
      if (this.showBookingSlotPreview) {
        this.showContactFormPreview = false
        this.showSendMessagePreview = false
      }
      if (!this.showSendMessagePreview && !this.showBookingSlotPreview) {
        this.showContactFormPreview = true
      }
    },
    contactFormIsPrivacyInformationEnabled() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.isPrivacyInformationEnabled = this.contactFormIsPrivacyInformationEnabled
      this.updateDialogPartial({ contactForm })
    },
    contactFormPrivacyInformationHtml() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.privacyInformationHtml = this.contactFormPrivacyInformationHtml
      this.updateDialogPartial({ contactForm })
    },
    contactFormBookingSlotTitle() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.bookingSlotTitle = this.contactFormBookingSlotTitle
      this.updateDialogPartial({ contactForm })
    },
    contactFormBookingSlotCopy() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.bookingSlotCopy = this.contactFormBookingSlotCopy
      this.updateDialogPartial({ contactForm })
    },
    contactFormSendMessageTitle() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.sendMessageTitle = this.contactFormSendMessageTitle
      this.updateDialogPartial({ contactForm })
    },
    contactFormSendMessageCopy() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.sendMessageCopy = this.contactFormSendMessageCopy
      this.updateDialogPartial({ contactForm })
    },
    contactFormIsDefaultContactFormInsideChat() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.isDefaultContactFormInsideChat = this.contactFormIsDefaultContactFormInsideChat
      this.updateDialogPartial({ contactForm })
    },
    contactFormIsBookingEnabled() {
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.isBookingEnabled = this.contactFormIsBookingEnabled
      this.updateDialogPartial({ contactForm })

      if (this.contactFormIsBookingEnabled) {
        this.contactFormFields.name.enabled = true
        this.contactFormFields.name.required = true

        this.contactFormFields.email.enabled = true
        this.contactFormFields.email.required = true

        this.contactFormFields.date.enabled = true
        this.contactFormFields.date.required = true

        this.contactFormFields.message.enabled = true
        this.contactFormFields.message.required = true
      }
    },
    'contactFormFields.name.enabled'(newVal) {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      if (!newVal) {
        updatedFields.name.required = false
      }
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.fields = updatedFields
      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.name.required'() {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      if (updatedFields.name.required && !updatedFields.name.enabled) {
        updatedFields.name.enabled = true
      }
      contactForm.fields = updatedFields

      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.phone.enabled'(newVal) {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      if (!newVal) {
        updatedFields.phone.required = false
      }
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.fields = updatedFields
      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.phone.required'() {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      if (updatedFields.phone.required && !updatedFields.phone.enabled) {
        updatedFields.phone.enabled = true
      }
      contactForm.fields = updatedFields

      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactForm.fields.email.enabled'(newVal) {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      if (!newVal) {
        updatedFields.email.required = false
      }
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.fields = updatedFields
      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.email.required'() {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      if (updatedFields.email.required && !updatedFields.email.enabled) {
        updatedFields.email.enabled = true
      }
      contactForm.fields = updatedFields

      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.date.enabled'(newVal) {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      if (!newVal) {
        updatedFields.date.required = false
      }
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.fields = updatedFields
      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.date.required'() {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      if (updatedFields.date.required && !updatedFields.date.enabled) {
        updatedFields.date.enabled = true
      }
      contactForm.fields = updatedFields

      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.message.enabled'(newVal) {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      if (!newVal) {
        updatedFields.message.required = false
      }
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.fields = updatedFields
      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.message.required'() {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      if (updatedFields.message.required && !updatedFields.message.enabled) {
        updatedFields.message.enabled = true
      }
      contactForm.fields = updatedFields

      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.country.enabled'(newVal) {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      if (!newVal) {
        updatedFields.country.required = false
      }
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.fields = updatedFields
      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.country.required'() {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      if (updatedFields.country.required && !updatedFields.country.enabled) {
        updatedFields.country.enabled = true
      }
      contactForm.fields = updatedFields

      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.company.enabled'(newVal) {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      if (!newVal) {
        updatedFields.company.required = false
      }
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      contactForm.fields = updatedFields
      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    'contactFormFields.company.required'() {
      const updatedFields = JSON.parse(JSON.stringify(this.contactFormFields))
      const contactForm = JSON.parse(JSON.stringify(this.dialog.contactForm))
      if (updatedFields.company.required && !updatedFields.company.enabled) {
        updatedFields.company.enabled = true
      }
      contactForm.fields = updatedFields

      this.updateDialogPartial({ contactForm })
      this.contactFormFields = JSON.parse(JSON.stringify(updatedFields))
    },
    hasLoadedWidgetTranslation(newVal) {
      if (newVal) {
        this.loadTranslation()
      }
      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })
    },
    tWebConnectContactFormText() {
      if (this.tWebConnectContactFormText !== this.selectedWidgetTranslation.contactFormText) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ contactFormText: this.tWebConnectContactFormText })
      }
    },
    tWebConnectPrivacyInformationHtml() {
      if (this.tWebConnectContactFormText !== this.selectedWidgetTranslation.privacyInformationHtml) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ privacyInformationHtml: this.tWebConnectPrivacyInformationHtml })
      }
    },
    tWebConnectSendMessageDefaultTitle() {
      if (this.tWebConnectSendMessageDefaultTitle !== this.selectedWidgetTranslation.sendMessageDefaultTitle) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ sendMessageDefaultTitle: this.tWebConnectSendMessageDefaultTitle })
      }
    },
    tWebConnectSendMessageDefaultCopy() {
      if (this.tWebConnectSendMessageDefaultCopy !== this.selectedWidgetTranslation.sendMessageDefaultCopy) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ sendMessageDefaultCopy: this.tWebConnectSendMessageDefaultCopy })
      }
    },
    tWebConnectBookingSlotDefaultTitle() {
      if (this.tWebConnectBookingSlotDefaultTitle !== this.selectedWidgetTranslation.bookingSlotDefaultTitle) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ bookingSlotDefaultTitle: this.tWebConnectBookingSlotDefaultTitle })
      }
    },
    tWebConnectBookingSlotDefaultCopy() {
      if (this.tWebConnectBookingSlotDefaultCopy !== this.selectedWidgetTranslation.bookingSlotDefaultCopy) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ bookingSlotDefaultCopy: this.tWebConnectBookingSlotDefaultCopy })
      }
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('validate-step-6')
  },
  methods: {
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial',
      setLoadedWidgetTranslation: 'widgetTranslation/setLoadedWidgetTranslation',
      setHasWidgetTranslationUpdated: 'widgetTranslation/setHasWidgetTranslationUpdated',
      updateWidgetTranslation: 'widgetTranslation/updateWidgetTranslation'
    }),
    loadTranslation() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        this.tWebConnectContactFormText = this.selectedWidgetTranslation.contactFormText
        this.tWebConnectPrivacyInformationHtml = this.selectedWidgetTranslation.privacyInformationHtml
        this.tWebConnectSendMessageDefaultTitle = this.selectedWidgetTranslation.sendMessageDefaultTitle
        this.tWebConnectSendMessageDefaultCopy = this.selectedWidgetTranslation.sendMessageDefaultCopy
        this.tWebConnectBookingSlotDefaultTitle = this.selectedWidgetTranslation.bookingSlotDefaultTitle
        this.tWebConnectBookingSlotDefaultCopy = this.selectedWidgetTranslation.bookingSlotDefaultCopy
      }
    },
    toggleFields(field) {
      this.contactFormFields[field].enabled = !this.contactFormFields[field].enabled
    },
    displayAppointmentBookedPreview() {
      if (this.HAS_SCHEDULING_ACCESS) {
        this.showBookingSlotPreview = !this.showBookingSlotPreview
      }
    },
    decodeHTML() {
      const txt = document.createElement('textarea')
      txt.innerHTML = this.dialog.contactForm.privacyInformationHtml
      return txt.value
    },
    lightenDarkenColor(color, percent) {
      if (color[0] === '#') {
        color = color.slice(1)
      }

      const num = parseInt(color, 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = ((num >> 8) & 0x00ff) + amt
      const G = (num & 0x0000ff) + amt
      /* eslint-disable no-mixed-operators */
      return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
        .toString(16)
        .slice(1)}`
    }
  }
}
</script>
<style lang="scss" scoped>
.upper-case {
  color: #262629;
}
</style>
<style lang="scss">
.inside-chat-cf {
  width: 280px;
  min-height: 586px;
  background: #ffffff;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;

  &__footer {
    &__toolbar {
      height: 36px;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }

    &__message {
      padding: 17px 20px 24px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.inside-chat-cf__footer__message--bottom-space {
        padding-bottom: 36px;
      }

      &__text {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #b7b7c1;
      }
    }
  }

  &__content {
    background: white;
    padding-top: 40px;
    max-height: 404px;
    padding: 40px 0 10px 10px;

    .scrollbar-track {
      width: 3px;
      right: 3px;
    }

    .scrollbar-thumb {
      width: 3px;
      background: var(--contact-form-inside-background) !important;
    }

    .c-scroll-view__content {
      padding-right: 18px;
    }

    .inside-chat-cf__content--inner-scroll-view {
      .c-scroll-view__content {
        padding-right: 0;
      }
    }

    &__first-message {
      background: #eff1f5;
      padding: 16px 0 16px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #262629;
      border-radius: 13px 13px 13px 0;
      margin-right: 25px;
      font-size: 10px;
      margin-bottom: 10px;
    }

    &__second-message {
      background: #edf6fc;
      padding: 16px 0 16px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #262629;
      border-radius: 13px 13px 0px 13px;
      font-size: 10px;
      margin-bottom: 10px;
      margin-left: 40px;
    }
  }

  &__heading {
    height: 82px;
    background: #3034f7;
    padding-top: 20px;
    position: relative;
    border-radius: 13px 13px 0 0;

    &__minimize {
      position: absolute;
      top: 20px;
      right: 20px;

      svg {
        transform: rotate(270deg);
      }
    }

    &__title {
      font-weight: 400;
      padding-left: 22px;
      color: #ffffff;
      display: flex;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 16px;
        margin-left: 20px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 20px;
    }

    &__endcall {
      max-height: 35px;
      width: 35px;
      min-width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      background: #ed0d0d;
      border-radius: 6px;
      cursor: pointer;
    }

    &__button {
      width: 110px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.25);
      border-radius: 6px;
      margin-right: 10px;
      font-weight: 400;
      font-size: 9px;
      line-height: 15px;
      color: #262629;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.configure-dialog-step-six {
  .quill-editor {
    height: 150px;
    .ql-toolbar {
      max-height: 42px;
    }
    .ql-disabled {
      background-color: #eee;
    }
    .ql-container {
      max-height: 108px;
      .ql-editor {
        font-family: 'Lato';
      }
    }
  }
  .feature-disabled {
    .feather-icon {
      color: #c4c4c4;
    }

    div {
      color: #c4c4c4;
    }

    .vs-icon {
      color: rgba(var(--vs-primary), 1);
      font-size: 1.2rem;
    }

    textarea {
      color: #c4c4c4;
    }
  }

  .contact-form-scroll-area {
    height: 550px;
  }

  .default-contact-form-preview {
    .scrollbar-thumb {
      background: var(--contact-form-darken-background) !important;
    }
  }

  &-checkbox input:checked + .vs-checkbox {
    border-width: 2px !important;
    border-style: solid !important;
    border-color: var(--contact-form-font-color) !important;
    background: var(--contact-form-darken-background) !important;
  }

  .ql-editor {
    min-height: 100px;
    font-family: 'Lato';
  }

  .headline {
    color: #262629;
    font-weight: 600;
    font-size: 18px;
  }

  .close-box {
    width: 35px;
    height: 35px;
    background-color: rgba(var(--vs-secondary), 1);
    border-radius: 4px;
  }

  .vs-switch {
    min-width: 45px !important;
  }

  .vs-checkbox {
    width: 20px;
    height: 20px;
  }

  .vs-icon {
    font-size: 0.7rem;
  }

  .pa-color-picker {
    width: 310px;
    min-width: 310px;
    max-width: 100%;
    height: 40px;
    cursor: pointer;
  }

  .flex-view {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .contact-form-fields {
    &-item {
      min-width: 310px;
      height: 45px;
      margin: 5px;
      border: 1px solid #cbcbcb;
      border-radius: 6px;
      align-items: center;
      padding: 8px;

      &-required {
        text-transform: lowercase;
      }
    }

    .vs-component.con-vs-checkbox {
      input:checked + .vs-checkbox {
        border-width: 2px !important;
        border-style: solid !important;
        border-color: #275d73 !important;
        background: #275d73 !important;
      }
    }
  }

  .feedback-message-container {
    width: 100%;
    height: 100%;

    margin-top: 200px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;

    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      hyphens: auto;
    }

    &-copy {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .message-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    &-privacy-policy {
      color: var(--contact-form-font-color);

      a {
        color: var(--contact-form-font-color);
        text-decoration: underline;
      }
    }

    &-title {
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      padding-top: 20px;
    }

    &-copy {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      text-align: justify;
    }

    &-upper {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      text-align: center;
    }

    &-fields {
      display: flex;
      height: 45px;
      background-color: rgba(230, 236, 241, 0.6);
      border-radius: 6px;
      padding-top: 14px;
      padding-left: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #262629;
    }

    .ht-100 {
      height: 100px;
    }

    &-action-button {
      height: 35px;
      border-radius: 6px;

      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      cursor: pointer;
    }

    &-action-button:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }

    &-cancel-button {
      height: 35px;
      background: transparent;
      border-radius: 6px;

      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      cursor: pointer;
    }

    &-cancel-button:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
  }

  &__select-link-message {
    padding: 10px;
    color: rgb(59, 134, 247);
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &__icon {
      margin-right: 5px;
    }
  }
}
</style>
